import React from "react";
import Layout from "@components/layout";
import Header from "@components/header/header";

import FormationIntro from "@components/formation-intro";
import FormationComprendre from "@components/formation-comprendre";
import FormationUtiliser from "@components/formation-utiliser";
import FormationAnticiper from "@components/formation-anticiper";
import Contact from "@components/contact";
import Footer from "@components/footer";
import MobileNav from "@components/mobile-nav";


const Formation = () => {
  return (
    <Layout pageTitle="Formations || Prompt Breeders">
      <Header />
      <FormationIntro />
      <FormationComprendre />
      <FormationUtiliser />
      <FormationAnticiper />
      <Contact />
      <Footer />
      <MobileNav />
    </Layout>
  );
};

export default Formation;
