import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import testimonial2 from "@images/testimonials/testimonials-1-shape-4.png"; //for background image
import { FORMATION_ANTICIPER_DATA } from "@data";
const FormationAnticiper = () => {
  const { sectionTitle, paragraph,options } = FORMATION_ANTICIPER_DATA;
  return (
    <section id="formation-anticiper" className="service-one">
      <div
        className="testimonials-1-shape-4"
        style={{ backgroundImage: `url(${testimonial2})` }}
      ></div>
      <Container>
        <div className="block-title text-center">
          <p>{sectionTitle.text}</p>
          <h3>{sectionTitle.title}</h3>
        </div>
        <div className="cta-two__text new-line">
          <p>{paragraph}</p>
        </div>
        <ul className="list-unstyled cta-two__list">
          {options.map(({ label }, index) => (
            <li key={`options-key-${index}`}>
              <i className="fa fa-check-circle"></i>
              {label}
            </li>
          ))}
        </ul>
      </Container>
    </section>
  );
};

export default FormationAnticiper;
