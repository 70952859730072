import React from "react";
import { Row, Col, Container } from "react-bootstrap";
import bgShape1 from "@images/banner/banner-shape-1-1.png";
import bgShape2 from "@images/banner/banner-shape-1-2.png";
import bannerBG from "@images/banner/banner-bg-1.png";
import bannerShape1 from "@images/shapes/banner-1-1.png";
import bannerShape2 from "@images/banner/banner-shape-1-4.png";
import image from "@images/banner/banner-img-1.png";

const FormationIntro = () => {
  return (
    <section className="banner-one" id="formation">
      <img src={bgShape1} alt="Banner-Shape-1" className="banner-shape-1" />
      <div
        className="banner-one__bg"
        style={{ backgroundImage: `url(${bannerBG})` }}
      ></div>
      <Container>
        <Row>
          <Col xl={8} lg={8}>
            <div className="banner-one__content">
              <h3>
              Generative AI: <br /> Understand, <br /> Adopt, and <br /> Anticipate
               
              </h3>
              
              <p>
              Prompt Breeders offers customized training sessions tailored to your context and challenges.
              <br />Discover the fundamentals of AI, explore generative technologies, and understand the impact of ChatGPT.
              <br />Learn to use generative AI in your daily life, understanding and mastering the risks and limitations.
              <br />Explore the latest advancements, from autonomous agents to AI as a Service.
              <br />Anticipate and prepare for the future by familiarizing yourself with emerging trends.              
              </p>
            </div>
          </Col>

        </Row>
      </Container>
    </section>
  );
};

export default FormationIntro;
